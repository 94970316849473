import React from "react";
import "./rangeslider.scss";
import { langType } from "../lang";
import car from "../../../assets/JDK/car.png";
import Card from "../Card/Card";
import "./rangeslider1.css";
import { CardText, imagesArray } from "../Card/cardText";
import { useRef, useState, useEffect } from "react";

const RangeMobile = ({ lang }) => {
  const setComponent = (item, index) => {
    return (
      <div className={"cards1"}>
        <div className={"content"}>
          <h1> {index + 1} </h1>
          <img className={"imgcard"} src={imagesArray[index]} />
        </div>
        <span className={"textcards1"}>{item}</span>
      </div>
    );
  };

  return (
    <div className="rangeMobile">
      {CardText[lang].map((item, index) => {
        return setComponent(item, index);
      })}
    </div>
  );
};

export default RangeMobile;
