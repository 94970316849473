import "./Work.scss";
import illustr2 from "../../../assets/JDK/second.png";
//";
import Popup from "reactjs-popup";
// import "reactjs-popup/dist÷/index.css";
import PopupContent from "../PopupJDK/Popup";
import { langType } from "../lang";

const Work = ({ lang }) => {
  return (
    <div className="Work">
      {" "}
      <Popup
        trigger={
          <div className={"pulse"}>
            <div className="mainButton1">{langType[lang].work.t6}</div>
          </div>
        }
        position="center center"
        modal
      >
        <PopupContent lang={lang} />
      </Popup>
      <div id={"workS"}>
        <div className="WorkContainer">
          <h3>{langType[lang].work.t1H}</h3>
          <div className="WorkWrapper">
            <img className={"imageWork"} src={illustr2} alt="car" />
            <div className="list list1">
              <div className="firstContainer">
                <div className="listblock">
                  <div className="titleList">
                    <span>01 </span>
                    {langType[lang].work.t1}
                  </div>
                </div>
                <div className="listblock">
                  <div className="titleList">
                    <span>02 </span>
                    {langType[lang].work.t2}
                  </div>
                </div>
                <div className="listblock">
                  <div className="titleList">
                    <span>03 </span>
                    {langType[lang].work.t3}
                  </div>
                </div>
              </div>

              <div className="secondContainer">
                <div className="listblock">
                  <div className="titleList">
                    <span>04 </span>
                    {langType[lang].work.t4}
                  </div>
                </div>
                <div className="listblock">
                  <div className="titleList">
                    <span>05 </span>
                    {langType[lang].work.t5}
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="list list2">
              <div className="firstContainer">
                <div className="listblock">
                  <div className="titleList">
                    <span>01 </span>
                    {langType[lang].work.t1}
                  </div>
                </div>
                <div className="listblock">
                  <div className="titleList">
                    <span>02 </span>
                    {langType[lang].work.t2}
                  </div>
                </div>
                <div className="listblock">
                  <div className="titleList">
                    <span>03 </span>
                    {langType[lang].work.t3}
                  </div>
                </div>{" "}
                <div className="listblock">
                  <div className="titleList">
                    <span>04 </span>
                    {langType[lang].work.t4}
                  </div>
                </div>
              </div>
              <div className="secondContainer">
                <div className="listblock">
                  <div className="titleList">
                    <span>05 </span>
                    {langType[lang].work.t5}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Work;
