import img1 from "../../../assets/JDK/cars/car1.png"
import img2 from "../../../assets/JDK/cars/car2.png"
import img3 from "../../../assets/JDK/cars/car3.png"
import img4 from "../../../assets/JDK/cars/car4.png"
import img5 from "../../../assets/JDK/cars/car5.png"
import img6 from "../../../assets/JDK/cars/car6.png"


export const car1 = {
  name: "Микроавтобусы",
  price: "Цена: от 25 BYN/час",
  first_text: "Вместимость: до 12 м³",
  second_text: "Грузоподьемность: до 1.5 тонн",
  img: img1,
};
export const car2 = {
  name: "Мебельные фургоны",
  price: "Цена: от 30 BYN/час",
  first_text: "Вместимость:  до 28 м³",
  second_text: "Грузоподьемность: до 4.5 тонн",
  img: img2,
};

export const car3 = {
  name: "Манипуляторы",
  price: "Цена: от 70 BYN/час",
  first_text: "Размеры: до 9 м.",
  second_text: "Грузоподьемность: до 1.5 тонн",
  img: img3,
};

export const car4 = {
  name: "Контейнеры",
  price: "Цена: от 250 BYN",
  first_text: "Вместимость: до 12 м³",
  second_text: "Грузоподьемность: до 8 тонн",
  img: img4,
};


export const car5 = {
    name: "Машина с пирамидой",
    price: "Цена: от 70 BYN",
    first_text: "Размеры: до 4 м.",
    second_text: "Тип кузова: открытый",
    img: img5,
  };

  
export const car6 = {
    name: "МАЗ",
    price: "Цена: от 35 BYN/час",
    first_text: "Тип кузова: открытый/закрытый",
    second_text: "Грузоподъёмность: от 5 до 30 тонн",
    img: img6,
  };

  