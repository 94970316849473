import "./BackToUs1.scss";
//";
import illustr2 from "../../../assets/JDK/SecondBG.png";
import {langType} from "../lang";
const BackToUs = ({lang}) => {
  return (
    <div className="BackToUs1">
      <div id={"BackToUs1"}>
        <div className="BackContainer">
          <h3>{langType[lang].back.t1H}</h3>
          <div className="imageContainer">
            <div className="imageList">
              <div className="back img1Back1">
                <div>
                  <p className="title1">{langType[lang].back.t1}</p>
                </div>
              </div>
              <div className="back img1Back1">
                {" "}
                <div>
                  <p className="title1">{langType[lang].back.t2}</p>
                 </div>
              </div>
              {/* <img src={ad1} alt="ad1" />
              <img src={ad2} alt="ad2" /> */}
            </div>

            <img src={illustr2} className={'backUs-image'} alt={'img'}/>


            <div className="imageList imagelist2">
              {" "}
              <div className="back img1Back1">
                {" "}
                <div>
                  <p className="title1">{langType[lang].back.t3}</p>
                 </div>
              </div>
              <div className="back img1Back1">
                {" "}
                <div>
                  <p className="title1">{langType[lang].back.t4}</p>

                </div>
              </div>
              {/* <img src={ad3} alt="ad1" />
              <img src={ad4} alt="ad1" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackToUs;
