import React from "react";
import "./rangeslider.scss";
import car from "../../../assets/JDK/car.png";
import Card from "../Card/Card";
import RangeMobile from "./rangemobile";
// import {ScrollableAnchor} from "react-scrollable-anchor";
import { langType } from "../lang";
const genSlideStyle = (value) => {
  return {
    point: {
      left: `calc(${value * 16.67}% - ${6 + 3 * value}px)`,
    },
    range: {
      width: `${value * 16.67}%`,
    },
  };
};

class RangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  render() {
    const slideStyle = genSlideStyle(this.state.value);
    return (
      <div className="range">
        <div id={"Portfolio"}>
          <h1 className="title">{langType[this.props.lang].range.t1}</h1>
          <div className={"scroll-arrow"}></div>
          <div className={"scroll-arrow"}></div>
          <div className={"scroll-arrow"}></div>

          <div className="rangeContainer">
            <span className="range-value" style={slideStyle.range} />
            <span className="circle" style={slideStyle.point}>
              <span
                className="circle-image"
                style={{
                  backgroundImage: `url(${car})`,
                }}
              />
            </span>

            <input
              className="range-slide"
              name="range"
              type="range"
              min="0"
              max="6"
              value={this.state.value}
              step="1"
              onChange={this.handleChange}
            />

            <span className="road"></span>
          </div>
          <div className="cards">
            {<Card lang={this.props.lang} count={this.state.value} />}
          </div>
          <RangeMobile lang={this.props.lang} />
        </div>
      </div>
    );
  }
}

export default RangeSlider;
