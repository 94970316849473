import icon1 from "../../../assets/JDK/range/1.png";
import icon2 from "../../../assets/JDK/range/2.png";
import icon3 from "../../../assets/JDK/range/3.png";
import icon4 from "../../../assets/JDK/range/4.png";
import icon5 from "../../../assets/JDK/range/5.png";

export const CardText = {
  ru: [
    "2018 год: год основания компании,  запуск проекта по аутсорсингу персонала в г. Минске\n",
    "2019 год: Открытие офиса в г. Минске",
    "2020 год: Расширение бизнеса, запуск направления по грузоперевозкам в г. Минске и Минской области\n",
    "2021 год: Расширение бизнеса, Грузоперевозки по Республике Беларусь\n",
    "2022 год: Открытие офиса в Киргизии, выход на международный рынок грузоперевозок\n",
    "2024 год: Открытие офиса в Казахстане, расширение географии перевозок",
  ],
  en: [
    "2018: Year of company foundation, launch of the personnel outsourcing project in Minsk",
    "2019: Opening of an office in Minsk",
    "2020: Business expansion, launch of freight transportation direction in Minsk and Minsk region",
    "2021: Business expansion, Freight transportation across the Republic of Belarus",
    "2022: Opening of an office in Kyrgyzstan, entry into the international freight transportation market",
    "2024: Opening of an office in Kazakhstan, expansion of transportation geography",
  ],
  ch: [
    "2018年：公司成立之年，在明斯克启动人员外包项目",
    "2019年：明斯克办事处开业",
    "2020年：业务扩展，在明斯克和明斯克地区启动货运方向",
    "2021年：业务拓展，贝lar国内货运",
    "2022年：在吉尔吉斯斯坦开设办事处，进入国际货运市场",
    "2024年：在哈萨克斯坦开设办事处，扩展运输地理",
  ],
};

export const imagesArray = [icon1, icon2, icon3, icon5, icon4, icon5, icon5];
