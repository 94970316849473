import React, { useState } from "react";
import "./App.css";
import "./AppJDK.css";

import { ThemeProvider } from "styled-components";
import telegram from "../main";
import Header from "../components/JDK/Header/Header";
import Main from "../components/JDK/Main/Main";
import Services from "../components/JDK/Services/Services";
import Portfolio from "../components/App/Portfolio/Portfolio";
import Work from "../components/JDK/Work/Work";
import BackToUs from "../components/JDK/BackToUs/BackToUs";
import Cars from "../components/JDK/Cars/Cars";
import OurPartners from "../components/App/OurPartners/OurPartners";
import Footer from "../components/JDK/Footer/Footer";
import { Burger, Menu } from "./App/Header/components";
import { theme } from "./theme";
import { Link } from "react-router-dom";
import RangeSlider from "./JDK/RangeSlider";
// //";
import { langType } from "./JDK/lang";

const AppRouteJDK = () => {
  const menuId = "main-menu";
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState("ru");
  telegram();
  return (
    <ThemeProvider theme={theme}>
      <div className="App" id="App">
        <Burger
          lang={lang}
          open={open}
          setOpen={setOpen}
          aria-controls={menuId}
        />
        <Menu lang={lang} open={open} setOpen={setOpen} id={menuId} />
        <div className="headerMain">
          <Header setLang={setLang} lang={lang} />
          <Main lang={lang} />
        </div>
        <Services lang={lang} />
        <div id={"Portfolio"}>
          <RangeSlider lang={lang} />
        </div>
        <div className={"quote"}>{langType[lang].quote}</div>
        <Work lang={lang} />
        <BackToUs lang={lang} />
        <Cars lang={lang} />
        <OurPartners lang={lang} />
        <Footer lang={lang} />
      </div>
    </ThemeProvider>
  );
};

export default AppRouteJDK;
